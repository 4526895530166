import React from 'react';
import horizontalLogo from '../assets/images/logo_horizontal.png';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className='flex flex-col items-center bg-[#141414] text-white p-5 md:p-10'>
            <div className='h-12 md:h-24' />
            <h1 className='text-usc-gold text-[36px] md:text-[48px] font-bold text-center'>
                Privacy Policy
            </h1>
            <div className='h-6 md:h-12' />
            <div className='max-w-4xl text-[18px] md:text-[20px] space-y-6'>
                <p>
                    This Privacy Policy is created to protect the privacy of users of the mobile application branded as "Fallasana," developed and operated by Langugo LLC. This policy details how data collected through the Fallasana application is processed, used, and safeguarded. By using the application, you agree to this Privacy Policy and accept that your personal data will be processed as described herein.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>1. Non-Collection of Personal Information</h2>
                <p>
                    The Fallasana application is committed to protecting users' identities and personal information. In this context, we do not collect any email addresses, phone numbers, social media account information, or identification details from our users. Users are not required to disclose any personal information while using the application, allowing them to remain completely anonymous.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>2. Collection of Anonymous Demographic Information</h2>
                <p>
                    The Fallasana application may request some anonymous demographic information to provide a more personalized experience and deliver more meaningful fortune readings. This information may include name, date of birth, birth time, birthplace, education level, marital status, and financial status. This demographic information is collected and processed without linking it to user identities and is completely anonymous.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>3. Collection of Anonymous Face Data</h2>
                <p>
                    Our application is anonymous; however, for the 'Face Analysis' service, users are requested to provide facial selfies. Facial data is analyzed through the OpenAI service API, and based on the analysis results provided to the user, it is immediately deleted. The requested data is not stored anywhere.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>4. Automatic Information Collection and Usage</h2>
                <p>
                    Fallasana may automatically collect certain anonymous usage data to improve application performance, enhance user experience, and analyze the application's overall functioning. This data may include technical information such as in-app usage durations, frequently visited sections of the application, and device types. This information is not linked to user identities and is used solely to analyze general user trends.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>5. Third-Party Service Providers</h2>
                <p>
                    The Fallasana application may collaborate with third-party service providers to deliver better services to users. These third parties may operate in areas such as in-app advertising, payment processing, and analytics services. However, these third parties do not have access to users' personal information and can only process anonymously collected data.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>6. Advertisements and Earning Free Diamonds</h2>
                <p>
                    The Fallasana application may include advertisements that offer users the opportunity to earn free diamonds. These advertisements are provided by third parties and may be viewed with user consent. No personal information is collected or transferred to third parties during the advertisement viewing process. Users' tracking activities are recorded anonymously and used solely to provide related free diamond rewards.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>7. Data Security</h2>
                <p>
                    Fallasana implements security measures and protocols compliant with industry standards to ensure the safety of user information. All anonymously collected data is protected against unauthorized access, alteration, disclosure, or destruction. Fallasana regularly conducts security assessments and updates security protocols to ensure data security.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>8. Cookies and Similar Technologies</h2>
                <p>
                    Fallasana may use cookies and similar technologies to improve user experience and enhance application performance. Cookies are small data files saved to users' browsers, allowing them to have a better experience within the application. Users have the right to refuse the use of cookies by changing their browser settings; however, in such cases, access to certain features of the application may be restricted.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>9. Changes to the Privacy Policy</h2>
                <p>
                    Fallasana reserves the right to update or modify this Privacy Policy at any time. Any changes will be brought to users' attention, and the updated policy will be made accessible through the application. Continuing to use the application after changes to this Privacy Policy indicates your acceptance of the updated policy.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>10. User Rights</h2>
                <p>
                    Users have specific rights regarding the processing of their anonymously collected information. These rights may include requests for information, correction, or deletion of data. Users wishing to exercise these rights can contact the Fallasana support team.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>11. Jurisdiction</h2>
                <p>
                    This Privacy Policy is governed by and interpreted in accordance with the laws of the State of Wyoming, United States. In the event of any disputes related to the Fallasana application, only the courts of the State of Wyoming shall have jurisdiction.
                </p>

                <p>
                    By using the Fallasana application, you affirm that you have carefully read, understood, and accepted the above Privacy Policy. If you have any questions or concerns regarding the protection of your privacy, please feel free to contact us via the support email address provided below.
                </p>
                <p>
                    For any inquiries and suggestions, please use the email address: <a href="mailto:info@fallasana.com" className='text-usc-gold underline'>info@fallasana.com</a>
                </p>
                <p>Last Updated: September 1, 2024</p>
            </div>
            <div className='h-8 md:h-16' />
        </div>
    );
}

export default PrivacyPolicy;